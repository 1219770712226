import {
  Container,
  createStyles,
  IconButton,
  InputBase,
  makeStyles,
  Paper,
  Theme,
} from "@material-ui/core"
import React, { useState } from "react"
import { useEffect } from "react"
import { getTracking } from "./api"
import { TrackerDataResponse } from "./types"
import { DataGrid } from "@material-ui/data-grid"
import SearchIcon from "@material-ui/icons/Search"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import dayjs from "dayjs"
import "./App.css"

type ParsedTableData = {
  [x: string]: {
    id: string | number
    status: string
    date: Date | string
    terminal: string
  }[]
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      maxWidth: 300,
      margin: "4rem auto",
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    dataTable: {
      "overflow-x": "auto",
      padding: "1rem",
    },
    dataTableInner: {
      height: "auto",
      width: "100%",
      minWidth: "800px",
    },
  })
)

type DataProps = {
  rows: ParsedTableData[]
}

const DataTable = ({ rows }: DataProps) => {
  const classes = useStyles()
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 100,
    },
    {
      field: "status",
      headerName: "Status",
      editable: false,
      flex: 1,
    },
    {
      field: "date",
      headerName: "Date Added",
      editable: false,
      flex: 1,
    },
    {
      field: "terminal",
      headerName: "Terminal Location",
      flex: 1,
    },
  ]
  const renderDataTables = (row: ParsedTableData) => {
    return Object.keys(row).map((key) => {
      const data = row[key]

      return (
        <div className={classes.dataTable}>
          <div className={classes.dataTableInner}>
            <h2>{key}</h2>
            <DataGrid autoHeight={true} rows={data} columns={columns} />
          </div>
        </div>
      )
    })
  }

  return (
    <>
      <div className="data-table">
        {rows.map((row) => renderDataTables(row))}
      </div>
    </>
  )
}

function TrackerPage() {
  const [search, setSearch] = useState<string>("")
  const [result, setResult] = useState<TrackerDataResponse | null>(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (window.location.search) {
      const params = new URLSearchParams(window.location.search)
      const s = params.get("s" || "")
      if (s) {
        setSearch(s)
        getTracking(s)
          .then(({ data }) => {
            setResult(data)
          })
          .catch(() => setResult(null))
      }
    }
  }, [])

  const handleSearchButton = async (event: any) => {
    event.preventDefault()
    setLoading(true)
    if (search) {
      setResult(null)
      try {
        const result = await getTracking(search)
        if (result.status === 200) {
          setResult(result.data)
          window.history.pushState(null, "", `/?s=${search}`)
        }
      } catch (error) {
        toast.error("Tracking Number is Invalid ", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      }
    } else {
      // Add toast here not found
      setResult(null)
      toast.error("Tracking Number is required", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    setLoading(false)
  }

  const handleChange = (e: any) => {
    setSearch(e.target.value.trim())
  }

  const parseResData = (res: TrackerDataResponse | null) => {
    if (res != null) {
      let parsed: ParsedTableData[] = []
      for (const item of res.CargoItems) {
        const itemBlock = item.CargoTrackings.map((t) => {
          return {
            [t.trackingNumber]: t.Trackers.map((tr) => {
              return {
                id: tr.id,
                status: tr.status,
                date: dayjs(tr.dateAdded).format("MMM DD YYYY hh:mma"),
                terminal: tr.Terminal?.area,
              }
            }),
          }
        })

        parsed = [...parsed, ...itemBlock]
      }
      return parsed
    }
    return []
  }

  const classes = useStyles()

  return (
    <div className="tracker__main">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Paper component="form" className={classes.root}>
        <InputBase
          className={classes.input}
          placeholder="Search Tracking Number"
          inputProps={{
            "aria-label": "search tracking number",
            maxlength: 10,
          }}
          value={search}
          onChange={handleChange}
        />
        <IconButton
          type="submit"
          className={classes.iconButton}
          aria-label="search"
          disabled={loading}
          onClick={handleSearchButton}
        >
          <SearchIcon />
        </IconButton>
      </Paper>

      {loading ? (
        <div className="loading__container">
          <div className="infinity-2"></div>
        </div>
      ) : (
        <Container>
          <DataTable rows={parseResData(result)} />
        </Container>
      )}
    </div>
  )
}

function App() {
  return (
    <div className="App">
      <TrackerPage />
    </div>
  )
}

export default App
