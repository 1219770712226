import axios from "axios"

const gatewayURL = process.env.API_URL || "https://api.genesis.bukobus.com"

export const baseAxios = axios.create({
  baseURL: gatewayURL,
})

export const getTracking = (id: string) => {
  const config = {
    method: "get",
    url: `${gatewayURL}/cargo/${id}?tracker=true`,
    headers: {},
  }

  return baseAxios.get(config.url, {
    headers: config.headers,
  })
}
